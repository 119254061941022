import {
  MetricInfo,
  PartialBucketInfo,
  BucketInfoItem,
  Field,
  BucketInfo,
  Metric,
  NumericMetric,
  PartialMetricInfo,
  PartialKpiInfo,
  KpiInfo,
  MetricType
} from 'sg-dashboard-sdk';

/**
 * Generate a random int
 * @param min minimum inclusive
 * @param max maximum exclusive
 */
export function getRandomInt(min: number, max: number): number {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min)) + min; //The maximum is exclusive and the minimum is inclusive
}

/* eslint-disable */
export function debounce(func: (...args: any[]) => void, wait: number) {
  let timeout: NodeJS.Timeout | null = null;
  return function(this: any, ...args: any[]) {
    const self = this;
    if (timeout !== null) {
      clearTimeout(timeout);
    }
    const later = () => {
      timeout = null;
      func.apply(self, args);
    };
    timeout = setTimeout(later, wait);
  };
}

export function formatNumber(value: number | string): string {
  let valuetoTest = value;
  if (typeof valuetoTest == 'number') {
    valuetoTest = value.toString();
  }
  const splittedValue = valuetoTest.split('.');
  splittedValue[0] = splittedValue[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  if (splittedValue.length > 0) {
    return splittedValue.join('.');
  } else {
    return splittedValue[0];
  }
}

export const DATE_MATCHER_REGEX = /^[0-9]{4}-[0-9]{2}-[0-9]{2}\s*(?:\d{2}:\d{2})?(\:\d{2})?Z?$/;

export const testStringIsDate = (testedStr: string): boolean => {
  return testedStr.trim().match(DATE_MATCHER_REGEX) != null;
};

export function transformPartialMetricInfoIntoMetricInfo(
  dataSourceMetricFields: Field[],
  partialMetric: PartialMetricInfo
): MetricInfo | null {
  const metricData = partialMetric.data;
  let field;
  if (metricData.type === MetricType.Count) {
    field = { digitsNb: null, sortable: true, discardNullValueInAggregations: null, displayAs: null };
  } else {
    field = dataSourceMetricFields.find(field => field.id === (partialMetric.data as NumericMetric).field);
  }

  if (field && field !== null) {
    const data: any = { type: partialMetric.data.type, field: partialMetric.data.field };
    if (metricData.type !== MetricType.Count) {
      data.digitsNb = field.digitsNb ? field.digitsNb : 0;
      data.discardNullValueInAggregations = field.discardNullValueInAggregations ? true : false;
      data.displayAs = field.displayAs;
    }
    const metricInfo: MetricInfo = {
      label: field.label || partialMetric.label,
      data: data as Metric,
      sortable: field.sortable ? true : false,
      digitsNb: field.digitsNb ? field.digitsNb : 0,
      discardNullValueInAggregations: field.discardNullValueInAggregations ? true : false
    };
    return metricInfo;
  }
  return null;
}

export function checkChangedMetrics(
  dataSourceMetricFields: Field[],
  partialMetrics: PartialMetricInfo[]
): PartialMetricInfo[] {
  let changedMetrics = [];
  if (!dataSourceMetricFields.length) {
    changedMetrics = partialMetrics.filter(el => el.data.type !== MetricType.Count);
  } else {
    changedMetrics = partialMetrics.filter(
      ({ data: savedMetricData }) =>
        !dataSourceMetricFields.some(dMetricfield => {
          if (savedMetricData.type !== MetricType.Count) {
            return dMetricfield.id === savedMetricData.field && dMetricfield.metricAggregations
              ? dMetricfield.metricAggregations.includes(savedMetricData.type)
              : true;
          }
          return true;
        })
    );
  }
  return changedMetrics;
}
export function transformPartialMetricsInfoIntoMetricInfo(
  dataSourceMetricFields: Field[],
  partialMetrics: PartialMetricInfo[]
): MetricInfo[] {
  return partialMetrics.reduce((acc, currentPartialMetric) => {
    const metricInfo = transformPartialMetricInfoIntoMetricInfo(dataSourceMetricFields, currentPartialMetric);
    if (metricInfo) {
      return [...acc, metricInfo];
    }
    return acc;
  }, [] as MetricInfo[]);
}

export function transformPartialBucketInfoIntoBucketInfo(
  dataSourceBucketFields: Field[],
  partialBuckets: PartialBucketInfo[]
): BucketInfo {
  return partialBuckets.reduce((acc, currentPartialBucket) => {
    const field = dataSourceBucketFields.find(field => field.id === currentPartialBucket.data);
    if (field && field !== null) {
      const bucketInfo: BucketInfoItem = {
        label: field.label,
        data: field.id,
        sortable: field.sortable ? true : false,
        type: field.type
      };
      return [...acc, bucketInfo];
    }
    return acc;
  }, [] as BucketInfo);
}

export function transformPartialKpisIntoKpiInfo(
  dataSourceMetricFields: Field[],
  partialKpis: PartialKpiInfo[]
): KpiInfo[] {
  return partialKpis.reduce((acc, currentPartialKpi) => {
    const kpiPartialMetric = currentPartialKpi.value;
    const metricInfo = transformPartialMetricInfoIntoMetricInfo(dataSourceMetricFields, kpiPartialMetric);
    if (metricInfo) {
      const kpiInfo: KpiInfo = { value: metricInfo };
      if (currentPartialKpi.header) {
        const { text, metric, tooltip } = currentPartialKpi.header;
        kpiInfo.header = { text, tooltip };
        if (metric) {
          const headerMetricInfo = transformPartialMetricInfoIntoMetricInfo(dataSourceMetricFields, metric);
          if (headerMetricInfo) {
            kpiInfo.header.metric = headerMetricInfo;
          }
        }
      }
      if (currentPartialKpi.footer) {
        const { text, metric } = currentPartialKpi.footer;
        kpiInfo.footer = { text };
        if (metric) {
          const footerMetricInfo = transformPartialMetricInfoIntoMetricInfo(dataSourceMetricFields, metric);
          if (footerMetricInfo) {
            kpiInfo.footer.metric = footerMetricInfo;
          }
        }
      }
      return [...acc, kpiInfo];
    }
    return acc;
  }, [] as KpiInfo[]);
}
